/* eslint-disable @nx/enforce-module-boundaries */
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { SearchIcon } from '@mybridge/icons/Search';
import {
  Avatar,
  Button,
  CloseButton,
  HStack,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  List,
  ListItem,
  Stack,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
} from '@mybridge/ui';
import { forwardRef, useContext, useEffect, useState } from 'react';
import { getUserFullName, getUserProfilePic } from 'v4/lib/commons';
import { PostFormContext } from '../context';

export const PostFormTagPeople = forwardRef(({ ...props }, ref) => {
  const {
    setMoreOptionsActiveTab,
    setOpenMoreOptions,
    moreOptionsDisc,
    tagged,
    setTagged,
    allDegreeConnections,
    taggedListUsers
  } = useContext(PostFormContext);

  const [tagged_, setTagged_] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredUsers, setFilteredUsers] = useState(taggedListUsers);

  useEffect(() => {
    if (JSON.stringify(tagged) !== JSON.stringify(tagged_)) {
      setTagged_([...(tagged ?? [])]);
    }
  }, [tagged]);

  const isTagged = (user) => {
    return tagged_?.find?.((t) => t?.id === user?.id);
  };

  const tag = (user) => {
    if (!isTagged(user)) {
      setTagged_([...(tagged_ ?? []), user]);
    }
  };

  const untag = (user) => {
    const filtered = tagged_?.filter?.((t) => t?.id !== user?.id);
    setTagged_([...(filtered ?? [])]);
  };

  const onSelect = (user) => {
    if (isTagged(user)) {
      untag(user);
    } else {
      tag(user);
    }
  };

  const handleSearch = (event) => {
    const term = event.target.value;
    setSearchTerm(term);

    const filtered = taggedListUsers.filter(
      (user) =>
        user?.first_name?.toLowerCase()?.includes(term?.toLowerCase()) ||
        user?.last_name?.toLowerCase()?.includes(term?.toLowerCase())
    );

    setFilteredUsers(filtered);
  };

  const onDoneClick = () => {
    setTagged([...(tagged_ ?? [])]);
    moreOptionsDisc?.onClose?.();
  };
  return (
    <>
      <HStack mb={4}>
        <IconButton
          onClick={(e) => setMoreOptionsActiveTab(null)}
          variant="ghost"
        >
          <ChevronLeftIcon />
        </IconButton>
        <HStack flex={1}>
          <Text fontWeight="bold" color="brandPrimary.500">
            Tag People
          </Text>
        </HStack>
        <CloseButton
          onClick={(e) => {
            setOpenMoreOptions(false);
            setMoreOptionsActiveTab(null);
          }}
        />
      </HStack>
      <Stack>
        <HStack>
          <InputGroup>
            <InputLeftElement>
              <SearchIcon />
            </InputLeftElement>
            <Input
              value={searchTerm}
              onChange={handleSearch}
              pl={12}
              placeholder="Search..."
            />
          </InputGroup>
        </HStack>
        
          <Stack mt={4}>
            <Heading fontWeight="medium" size="sm">
              Tagged
            </Heading>
            <HStack flexWrap="wrap">
              {tagged_?.map?.((t, tIndex) => (
                <Tag p={2} key={tIndex}>
                  <Avatar
                    size="xs"
                    name={getUserFullName(t)}
                    src={getUserProfilePic(t)}
                  />
                  <TagLabel pl={2}>{getUserFullName(t)}</TagLabel>
                  <TagCloseButton pr={2} onClick={(e) => untag(t)} />
                </Tag>
              ))}
            </HStack>
            <Button
              onClick={onDoneClick}
              py={6}
              variant="primary"
              style={{ width: '100px' }}
            >
              Done
            </Button>
          </Stack>
        

        {filteredUsers?.length ? (
          <Stack mt={4}>
            <Heading fontWeight="medium" size="sm">
              Suggestions
            </Heading>
            <List maxH="64" overflowY="auto">
              {filteredUsers
                ?.filter?.((c) => !isTagged(c))
                ?.map((c, cIndex) => (
                  <ListItem
                    onClick={(e) => onSelect(c)}
                    p={2}
                    _hover={{ bg: 'gray.100' }}
                    key={cIndex}
                    as={HStack}
                    borderRadius="5"
                    cursor="pointer"
                  >
                    <Avatar
                      size="sm"
                      name={getUserFullName(c)}
                      src={getUserProfilePic(c)}
                    />
                    <Text>{getUserFullName(c)}</Text>
                  </ListItem>
                ))}
            </List>
          </Stack>
        ) : (
          ''
        )}
      </Stack>
    </>
  );
});
