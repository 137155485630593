/* eslint-disable @nx/enforce-module-boundaries */
import { ReactionsIcon } from '@mybridge/icons';
import { HStack, VStack } from '@mybridge/ui/layout';
import { useContext } from 'react';

import { Text } from '@mybridge/ui/text';
import { PostFormContext } from '../context';
import {
  Switch,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
} from '@mybridge/ui';

export const Reactions = () => {
  const {
    canComment,
    agreeDisAgree,
    setAgreeDisAgreeVisibility,
    setCanCommentVisibility,
  } = useContext(PostFormContext);

  return (
    <Popover placement="bottom-start" gutter={2}>
      <PopoverTrigger>
        <HStack
          as="button"
          color="#5B5B5B"
          fontSize="sm"
          width="202px"
          height="40px"
          padding="10px 14px"
          gap="10px"
          borderRadius="20px"
          border="1px 1px 0px 1px"
          boxShadow="0px 2px 6px 0px #00000033"
        >
          <ReactionsIcon />
          <Text color="gray.700">Reactions</Text>
        </HStack>
      </PopoverTrigger>

      <PopoverContent width="202px" borderRadius="10px">
        <PopoverBody>
          <VStack gap="8px" alignItems="flex-stretch">
            <HStack justifyContent="space-between" alignItems="center">
              <Text flex="1" textAlign="left" fontSize="sm">
                Comment
              </Text>
              <Switch
                id="canComment"
                value={canComment}
                defaultChecked={true}
                onChange={(e) => setCanCommentVisibility(e.target.checked)}
              />
            </HStack>
            <HStack justifyContent="space-between" alignItems="center">
              <Text flex="1" textAlign="left" fontSize="sm">
                Agree & Disagree
              </Text>
              <Switch
                id="agreeDisAgree"
                defaultChecked={true}
                value={agreeDisAgree}
                onChange={(e) => setAgreeDisAgreeVisibility(e.target.checked)}
              />
            </HStack>
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
